// import React, { ReactNode, useState } from 'react';
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
// import MyContext from './context/index'; 
// export const MyProvider = ({ children }: { children: ReactNode }) => {
//   const [theme, setTheme] = useState("dark");

//   const updateTheme = (newValue:string) => {
//     setTheme(newValue);
//   };

//   return (
//     <MyContext.Provider value={{ theme, updateTheme }}>
//       {children}
//     </MyContext.Provider>
//   );
// };


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} />
      </Routes>
    </Router>
  );
};

export default App;
