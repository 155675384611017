// import React, { useContext, useState } from 'react';
// import MyContext from '../../context/index';
import React, { useState, useRef } from 'react';
import './style.scss';
import classNames from 'classnames';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage';

import logo_black from '../../assets/images/home/logo_black.png';
import logo_white from '../../assets/images/home/logo_white.png';

const Home: React.FC = () => {
  // const { theme, updateTheme } = useContext(MyContext);
  // const { theme } = useContext(MyContext);
  // console.log(theme, '--------');

  //theme
  const [theme, setTheme] = useState("dark");
  const changeTheme = () => {
    setTheme(theme == 'dark' ? 'light' : 'dark');
  }

  // const [screenHeight, setScreenHeight] = useState('100vh');
  // const  isMobile = () => {  
  //   const userAgentInfo = navigator.userAgent;
  //   const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];  
  //   let flag = false;  
  //   for (let v = 0; v < Agents.length; v++) {  
  //       if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = true; break; }  
  //   }  
  //   return flag;  
  // }
  // useEffect(()=>{
  //   if(isMobile()){
  //     setScreenHeight(window.innerHeight + 'px');
  //   }
  // },[])
  

  const joinCommunity = () => {
    window.open("https://t.me/InFancy_AI");
  }

  const playAudio = () => {
    const audio:any = document.getElementById('audio');
    audio.play();
  }

  const fullpageRef:any = useRef(null);
  const handleNextButtonClick = () => {
    if (fullpageRef.current) {
      fullpageRef.current.next();
    }
  };

  const scroll = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <div className={classNames("home-container", theme)} onScroll={scroll}>
      <Fullpage ref={fullpageRef}>
        <FullPageSections>
          <FullpageSection>
            <div className={classNames("screen screen1")}>
              <img className={"logo"} src={theme == 'light' ? logo_black : logo_white}/>
              <div className={classNames("command")}>
                <div className={classNames("cursor")}>/</div>
                Decode Your Imagination
              </div>
              <div className={classNames("theme-btn")} onClick={changeTheme}></div>
              <div className={classNames("arrow-btn")} onClick={handleNextButtonClick}></div>
            </div>
          </FullpageSection>
          <FullpageSection>
            <div className={classNames("screen screen2")}>
              <div className={classNames("infancy")}>InFancy</div>
              <div className={classNames("noun")}>noun</div>
              <div className={classNames("pronounce")}>
                <div className={classNames("trumpet")} onClick={playAudio}></div>
                / 
                <div className={classNames("phonetic-symbols")}>ɪn ˈfænsi</div>
                /
              </div>
              <div className={classNames("intro")}>Generative AI Community</div>
              <div className={classNames("join-btn")} onClick={joinCommunity}>
                <div className={classNames("icon-tg")}></div>
                Join the community
              </div>

              <audio id="audio" controls className={classNames("audio")} src='/infancy.mp3'></audio>
              <div className={classNames("media-list")}>
                <a className={classNames("media-item twitter")} href="https://twitter.com/Infancy_ai" target="_blank" rel="noreferrer"></a>
              </div>
            </div>
          </FullpageSection>
        </FullPageSections>
      </Fullpage>
    </div>
  )
};

export default Home;
